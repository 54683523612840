import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"

export function Title({ data, first }) {
  const centered = data.center ? data.center : false
  return (
    <Wrapper>
      <StyledTitle
        center={centered}
        data-sal={!first && "slide-up"}
        data-sal-delay={300}
        data-sal-easing="ease"
        data-sal-duration="600"
      >
        {data && data.title ? data.title : ""}
      </StyledTitle>
      {data && data.underline && (
        <Hr
          center={centered}
          data-sal={!first && "slide-up"}
          data-sal-delay={350}
          data-sal-easing="ease"
          data-sal-duration="600"
        />
      )}
    </Wrapper>
  )
}

const StyledTitle = styled.h2`
  font-size: 2.2em;
  margin: 2em 0 1em 0;
  line-height: 1.2;
  word-spacing: 1px;
  font-weight: 700;
  color: ${props => props.theme.color.primary};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

const Hr = styled.hr`
  margin: 2.2rem 0;
  color: ${props => props.theme.color.secondary};

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
`

/**
 * 
 TitleBlock {
    title
    center
    underline
  }
 */

export const TitleBlock = {
  label: "Title",
  name: "TitleBlock",
  defaultItem: {
    TitleBlock: {
      title: "",
      center: false,
      underline: false,
    },
  },
  fields: [
    { name: "TitleBlock.title", label: "Title", component: "text" },
    { name: "TitleBlock.center", label: "Center", component: "toggle" },
    { name: "TitleBlock.underline", label: "Underline", component: "toggle" },
  ],
}
