import React from "react"
import styled from "styled-components"
import get from "lodash.get"
import { Wrapper } from "../components/style"
import ReactPlayer from "react-player"

export function Media({ data }) {
  if (!data.video) {
    return (
      <Wrapper>
        <ImageWrapper full={data.full}>
          {data.image && <img src={data.image.publicURL || data.image} />}
        </ImageWrapper>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <ReactPlayer
          url={data.video}
          className={"react-player"}
          width="100%"
          height="100%"
        />
      </Wrapper>
    )
  }
}

const ImageWrapper = styled.div`
  margin: 2em 0;
  border-radius: 3em;
  overflow: hidden;
  text-align: center;
  img {
    ${props =>
      props.full
        ? `
            width: 100%;
          `
        : `
            margin: 0 auto;
          `}
  }
`

/**
 * 
 
 MediaBlock{
  video 
  image{
     publicURL
   }
 }
 */

export const MediaBlock = {
  label: "Media",
  name: "MediaBlock",
  key: "MediaBlock",
  defaultItem: {
    MediaBlock: {
      video: "",
      full: false,
      image: "",
    },
  },
  fields: [
    {
      label: "Video",
      name: "MediaBlock.video",
      component: "text",
    },
    { name: "MediaBlock.full", label: "Full width", component: "toggle" },
    {
      label: "Image",
      name: "MediaBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
  ],
}
