import React from "react"
import { LinkHandle } from "../components/LinkHandle"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import { Row, Col } from "react-flexbox-grid"

export function ContentSidebySide({ data, first }) {
  return (
    <ContentWrapper first={first}>
      <Wrapper>
        <Row>
          <Col md={6}>
            <StyledSideContent
              data-sal={!first && "slide-up"}
              data-sal-delay={300}
              data-sal-easing="ease"
              data-sal-duration="600"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            </StyledSideContent>
          </Col>
          <Col md={6}>
            <StyledContent
              data-sal={!first && "slide-up"}
              data-sal-delay={350}
              data-sal-easing="ease"
              data-sal-duration="600"
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.side,
                  }}
                />
                {data.button && (
                  <LinkStyled>
                    <LinkHandle to={data.link}>{data.button}</LinkHandle>
                  </LinkStyled>
                )}
              </div>
            </StyledContent>
          </Col>
        </Row>
      </Wrapper>
    </ContentWrapper>
  )
}

const LinkStyled = styled.div`
  && {
    text-align: right;
    a {
      margin-top: 1em;
      font-size: 0.8em;
      padding: 0.4em 1.2em;
      display: inline-block;
      color: ${props => props.theme.color.secondary};
      border-radius: ${props => props.theme.radius.small};
      background: ${props => props.theme.color.white};
      text-decoration: none;
      :hover {
        background: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.white};
      }
    }
  }
`

const ContentWrapper = styled.div`
  && {
    margin: 4em 0;
    ${props =>
      props.first
        ? css`
            margin-top: -18%;
          `
        : ""}
  }
`

const StyledSideContent = styled.div`
  && {
    font-size: 0.8em;
    padding: 6em 4em;
    margin-top: -1em;
    border-radius: 3em;
    margin-right: -2em;

    background: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.white};
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2.7em;
    }
    p {
      margin-bottom: 0.8em;
    }
    transform: skewY(1deg);
    > div {
      transform: skewY(-1deg);
    }
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      margin-right: 0;
    }
  }
`

const StyledContent = styled.div`
  padding: 6em 4em;
  font-size: 0.8em;
  border-radius: 3em;
  margin-top: 1em;

  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.secondary};
  margin-left: -2em;
  ${props =>
    props.collumns &&
    css`
      column-count: ${props.collumns};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  p {
    margin: 0.8em;
  }
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    margin-left: 0;
    margin-top: -2em;
  }
`

/**
ContentSidebySideBlock {
    content
    side
    button
    link
  }
 */

export const ContentSidebySideBlock = {
  label: "Content Side By Side",
  name: "ContentSidebySide",
  key: "ContentSidebySide",
  defaultItem: {
    ContentSidebySideBlock: {
      content: "",
      side: "",
      button: "",
      link: "",
    },
  },
  fields: [
    {
      name: "ContentSidebySideBlock.content",
      label: "Content",
      component: "html",
    },
    {
      name: "ContentSidebySideBlock.side",
      label: "Side Content",
      component: "html",
    },
    {
      label: "Button",
      name: "ContentSidebySideBlock.button",
      component: "text",
    },
    {
      label: "Link",
      name: "ContentSidebySideBlock.link",
      component: "text",
    },
  ],
}
