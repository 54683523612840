import React, { Component } from "react"
import ReactPlayer from "react-player"
import styled, { css } from "styled-components"

export default function video(props) {
  return (
    <PlayerWrapper>
      <ReactPlayer
        className="header-react-header-player"
        playing={true}
        muted={true}
        controls={false}
        responsive={"true"}
        loop={true}
        url={props.video}
        height={"100%"}
        width={"100%"}
      />
    </PlayerWrapper>
  )
}

const PlayerWrapper = styled.div`
  && {
    position: relative;
    height: 100%;
    background: #000;
    /* padding-top: 56.25%; 
    .header-react-header-player {
      position: absolute;
      top: 0;
      left: 0;
    }
    */
  }
`
