import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"
import { LinkHandle } from "../components/LinkHandle"

export function HorizontalTitleText({ data, html, first }) {
  return (
    <StyledBackground
      color={data.color}
      background={data.image && data.image.publicURL}
    >
      <Wrapper>
        <StyledContent
          white={data.color || (data.image && data.image.publicURL)}
        >
          <Row>
            <Col md={6}>
              <h2
                data-sal={!first && "slide-up"}
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="600"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            </Col>
            <Col md={6}>
              <div
                data-sal={!first && "slide-up"}
                data-sal-delay="350"
                data-sal-easing="ease"
                data-sal-duration="600"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.content,
                  }}
                />
                {data.button && (
                  <LinkStyled
                    white={data.color || (data.image && data.image.publicURL)}
                  >
                    <LinkHandle to={data.link}>{data.button}</LinkHandle>
                  </LinkStyled>
                )}
              </div>
            </Col>
          </Row>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}

const LinkStyled = styled.div`
  && {
    text-align: left;
    a {
      padding: 0;
      display: inline-block;
      color: ${props =>
        props.white ? "#fff" : props.theme.color.primary}!important;
      text-decoration: none;
      :hover {
        font-weight: bold;
        color: ${props =>
          !props.white ? "#fff" : props.theme.color.primary}!important;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      text-align: center;
    }
  }
`
const StyledBackground = styled.div`
  && {
    padding: 2em 0;
    position: relative;
    ${props =>
      props.background
        ? css`
            background: url('${props.background}') no-repeat center;
          `
        : ""}
    background-size:cover;
    background-color: ${props => props.color || "#fff"};
  }
`

const StyledContent = styled.div`
  padding: 3rem 0;
  h2 {
    color: ${props => (props.white ? "#fff" : props.theme.color.primary)};
    font-size: 2.7em;
  }
  div {
    color: ${props => (props.white ? "#fff" : props.theme.color.secondary)};
    font-size: 0.95em;
  }
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    text-align: center;
  }
`

/**
 * 
 
 HorizontalTitleTextBlock{
   content
    title
    color
    button
    link
 }
 */

export const HorizontalTitleTextBlock = {
  label: "Horizontal Title and Text",
  name: "HorizontalTitleTextBlock",
  key: "HorizontalTitleTextBlock",
  defaultItem: {
    HorizontalTitleTextBlock: {
      content: "",
      title: "",
      color: "",
      button: "",
      link: "",
    },
  },
  fields: [
    {
      name: "HorizontalTitleTextBlock.title",
      label: "Title",
      component: "text",
    },
    {
      name: "HorizontalTitleTextBlock.content",
      label: "Content",
      component: "html",
    },
    {
      name: "HorizontalTitleTextBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      label: "Background Image",
      name: "HorizontalTitleTextBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    {
      label: "Button",
      name: "HorizontalTitleTextBlock.button",
      component: "text",
    },
    {
      label: "Link",
      name: "HorizontalTitleTextBlock.link",
      component: "text",
    },
  ],
}
