import React from "react"
import styled, { css } from "styled-components"
import { transparentize } from "polished"
import { Wrapper, Overlay, LinkButton } from "../components/style"
// import BackgroundImage from "gatsby-background-image"
import Video from "./Video"

export const Hero = ({ hero }) => {
  console.log(hero)
  return (
    <HeroCont video={hero.video} large={hero.large}>
      <HeroBackground>
        {hero.overlay && <Overlay large={hero.large} />}
        {hero.image && !hero.video && (
          <HeroImage image={hero.image.publicURL}></HeroImage>
        )}
        {hero.video && <Video video={hero.video}></Video>}
      </HeroBackground>
      <HeroContent large={hero.large}>
        {(hero.headline || hero.textline || hero.ctas) && (
          <BackgroundWrapper
            titleBackground={hero.titleBackground}
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="600"
          >
            {hero.headline && (
              <Headline
                dangerouslySetInnerHTML={{
                  __html: hero.headline,
                }}
              ></Headline>
            )}
            {hero.textline && (
              <Textline
                dangerouslySetInnerHTML={{
                  __html: hero.textline,
                }}
              ></Textline>
            )}
            {hero.ctas && (
              <Actions>
                {Object.keys(hero.ctas).map((key, i) => {
                  return (
                    <LinkButton
                      key={i}
                      primary={hero.ctas[key].primary}
                      to={hero.ctas[key].link}
                    >
                      {hero.ctas[key].label}
                      {hero.ctas[key].arrow && <span>&nbsp;&nbsp;→</span>}
                    </LinkButton>
                  )
                })}
              </Actions>
            )}
          </BackgroundWrapper>
        )}
      </HeroContent>
      {/* <Pattern /> */}
    </HeroCont>
  )
}

const HeroCont = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  top: 0;
  /* padding-top: ${props => props.theme.header.height}; */
  height: ${props => (props.large ? `95vh` : `65vh`)};
  

  ${props =>
    props.video &&
    css`
      height: calc(95vw / 1.7);
      @media (max-width: ${props => "1200px"}) {
        h2,
        p {
          display: none;
        }
      }
    `}


  ${props =>
    props.theme.hero.parallax &&
    css`
      transform-style: preserve-3d;
    `}
`

const BackgroundWrapper = styled(Wrapper)`
  && {
    max-width: 928px;
    margin: 0em;
    ${props =>
      props.titleBackground
        ? css`
            background: #ffffff;
            padding: 2em 2em 2em 2em;
            border-left: 1em solid ${props => props.theme.color.secondary};
            h2,
            p {
              color: ${props => props.theme.color.secondary};
            }
          `
        : css`
            background: transparent;
          `};
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      text-align: center;
      p {
        width: 100%;
      }
    }
  }
`

const HeroContent = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  min-height: 400px;
  max-width: 1020px;
  margin: 0 auto;
  ${props =>
    props.large &&
    css`
      height: 80vh;
      min-height: 600px;
    `};
  @media (min-width: ${props => props.theme.breakpoints.large}) {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.huge}) {
    max-width: 1400px;
    margin: 0 auto;
  }
`

const HeroBackground = styled.div`
  position: absolute !important;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: -${props => props.theme.hero.overlap};
  z-index: -1; */
  background-color: ${props => transparentize(0.1, props.theme.color.primary)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  height:100%;
  width: 100%;

  ${Overlay} {
    z-index: 1;
  }

  ${props =>
    props.theme.hero.parallax &&
    css`
      transform-style: preserve-3d;
      transform: translateZ(-1px) scale(2) translateY(25%);
    `}
`

export const Headline = styled.h2`
  font-size: 3.2em;
  line-height: 1;
  color: ${props => props.theme.color.white};
  word-spacing: 1px;
  font-weight: 700;
  margin: 0 0 0.4em 0;
  text-transform: none;
`

export const Textline = styled.p`
  font-size: 1em;
  width: 80%;
  line-height: 1.2;
  color: ${props => transparentize(0.2, props.theme.color.white)};
  word-spacing: 1px;
  font-weight: 400;
  margin: 0;
  text-transform: none;
`

export const Actions = styled.div`
  padding-bottom: 0.5rem;
  margin-top: 1em;
  > * {
    margin-right: 1rem;
  }
`

export const HeroImage = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:url('${props => props.image}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
