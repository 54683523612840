import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"

export function Content({ data, html, first }) {
  return (
    <Wrapper>
      <StyledContent
        center={data.center}
        collumns={data.number}
        data-sal={!first && "slide-up"}
        data-sal-delay={300}
        data-sal-easing="ease"
        data-sal-duration="600"
        dangerouslySetInnerHTML={{
          __html: data.text,
        }}
      ></StyledContent>
    </Wrapper>
  )
}

const StyledContent = styled.div`
  margin: 2em 0;
  font-size: 0.9em;
  color: ${props => props.theme.color.primary};
  ${props =>
    props.collumns &&
    css`
      column-count: ${props.collumns};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    column-count: 1;
  }
`
/**
ContentBlock {
          text
          number
          center
        }
 */

export const ContentBlock = {
  label: "Content",
  name: "ContentBlock",
  defaultItem: {
    ContentBlock: {
      text: "",
      number: "1",
      center: false,
    },
  },
  fields: [
    { name: "ContentBlock.text", label: "Content", component: "html" },
    { name: "ContentBlock.number", label: "Collumns", component: "text" },
    { name: "ContentBlock.center", label: "Center", component: "toggle" },
  ],
}
