import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"

export function IconRows({ data, html, first }) {
  return (
    <StyledBackground
      color={data.color}
      background={data.image && data.image.publicURL}
    >
      <Wrapper>
        <StyledContent background={data.background} center={data.center}>
          <StyledRow>
            {data.list &&
              data.list.map((block, i) => {
                return (
                  <StyledBlock
                    key={i}
                    xs
                    data-sal={!first && "slide-up"}
                    data-sal-delay={300 + (i + 1) * 50}
                    data-sal-easing="ease"
                    data-sal-duration="600"
                    color={block.color}
                    style={{ cursor: block.link ? "pointer" : "default" }}
                    onClick={() =>
                      block.link &&
                      typeof window !== "undefined" &&
                      window.open(block.link)
                    }
                  >
                    {block.image && (
                      <BlockImage
                        image={block.image}
                        backgroundType={data.backgroundType}
                      />
                    )}
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: block.name,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: block.content,
                      }}
                    />
                    {block.link && block.button && (
                      <a className={"button"} href={block.link}>
                        {block.button}
                      </a>
                    )}
                  </StyledBlock>
                )
              })}
          </StyledRow>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}

const BlockImage = styled.div`
&&{
  height:${props => (props.backgroundType ? "260px" : "80px")};
  border-radius:1em;
  background: url('${props => props.image.publicURL}') center no-repeat; 
  background-size:${props => (props.backgroundType ? "cover" : "contain")};
  margin-bottom:1.3em!important;
    @media (max-width: 520px) {
      height:300px
    }
  
}`

const StyledRow = styled(Row)`
  && {
    align-items: stretch;
    @media (max-width: 520px) {
      display: block;
      width: 100%;
    }
  }
`

const StyledBlock = styled(Col)`
  && {
    padding: 0.8em;

    h3 {
      font-size: 1.2em;
      margin: 0.5em 0;
      color: ${props => props.theme.color.primary};
    }
    div {
      font-size: 0.8em;
      margin: 0;
      color: ${props => props.theme.color.primary};
    }
    .button {
      font-size: 0.8em;
      text-decoration: none;
      padding: 0.5em 2em;
      border-radius: ${props => props.theme.radius.small};
      background-color: ${props => props.theme.color.secondary};
      color: ${props => props.theme.color.primary};
    }
  }
`

const StyledBackground = styled.div`
  && {
    position: relative;
    ${props =>
      props.background &&
      `background: url('${props.background}') no-repeat center;`}
    background-color: ${props => `${props.color}` || "#fff"};
    background-size:cover;
  }
`

const StyledContent = styled.div`
  && {
    padding: 3rem 0 0 0;
    ${props => props.center && "text-align:center"}
  }
`

/**
 * 
  IconRowsBlock{
      color
      backgroundType
      center
      list{
          name
          content
          button
          link
          color
        },
      ],
    }

 */

export const IconRowsBlock = {
  label: "Icon Rows",
  name: "IconRowsBlock",
  key: "IconRowsBlock",
  defaultItem: {
    IconRowsBlock: {
      color: "",
      backgroundType: false,
      center: false,
      list: [
        {
          name: "",
          content: "",
          button: "",
          link: "",
          color: "",
        },
      ],
    },
  },
  fields: [
    {
      label: "Block List",
      name: "IconRowsBlock.list",
      component: "group-list",
      description: "Blocks List",
      itemProps: item => ({
        key: item.id,
        label: item.name,
      }),
      defaultItem: () => ({
        name: "New Block",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Button",
          name: "button",
          component: "text",
        },
        {
          label: "Link",
          name: "link",
          component: "text",
        },

        {
          name: "color",
          label: "Background Color",
          component: "color",
          colorFormat: "hex",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
      ],
    },
    {
      name: "IconRowsBlock.center",
      label: "Center",
      component: "toggle",
    },
    {
      name: "IconRowsBlock.backgroundType",
      label: "Image Size Cover",
      component: "toggle",
    },
    {
      name: "IconRowsBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      label: "Background Image",
      name: "IconRowsBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    //...
  ],
}
