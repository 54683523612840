import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"

export function HorizontalBlocks({ data, html, first }) {
  return (
    <StyledBackground
      color={data.color}
      background={data.image && data.image.publicURL}
    >
      <Wrapper>
        <StyledContent background={data.background} center={data.center}>
          <StyledRow>
            {data.list.map((block, i) => {
              return (
                <StyledBlock
                  key={i}
                  xs
                  text={
                    data.image || data.color || block.image || block.color
                      ? "white"
                      : "black"
                  }
                  data-sal={!first && "slide-up"}
                  data-sal-delay={300 + (i + 1) * 50}
                  data-sal-easing="ease"
                  data-sal-duration="600"
                  image={block.image}
                  color={block.color}
                >
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: block.name,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: block.content,
                    }}
                  />
                  {block.link && block.button && (
                    <a className={"button"} href={block.link}>
                      {block.button}
                    </a>
                  )}
                </StyledBlock>
              )
            })}
          </StyledRow>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}

const StyledRow = styled(Row)`
  && {
    align-items: stretch;
  }
`

const StyledBlock = styled(Col)`
  && {
    padding: 2em;
    margin: 0 1em;
      ${props =>
        props.color || props.image
          ? css`
              box-shadow: 0 0.5rem 0.5rem 0rem rgba(41, 43, 45, 0.2);
            `
          : ""}
      ${props => props.color && `background-color:${props.color};`}
      ${props =>
        props.image &&
        css`
      background: url('${props.image.publicURL}') center no-repeat; 
      background-size:cover;
      `}
    h3 {
      font-size: 1.2em;
      margin: .5em 0;
      color: ${props =>
        props.text === "black"
          ? props.theme.color.black
          : props.theme.color.white};
    }
    div {
      font-size: 0.8em;
      margin: 0;
      opacity: 0.8;
      color: ${props =>
        props.text === "black"
          ? props.theme.color.black
          : props.theme.color.white};
    }
    .button {
      font-size: 0.8em;
      text-decoration: none;
      padding: 0.5em 2em;
      border-radius: ${props => props.theme.radius.small};
      background-color: ${props =>
        props.text === "black"
          ? props.theme.color.primary
          : props.theme.color.white};
      color: ${props =>
        props.text === "white"
          ? props.theme.color.primary
          : props.theme.color.white};
    }
  }
`

const StyledBackground = styled.div`
  && {
    position: relative;
    ${props =>
      props.background &&
      `background: url('${props.background}') no-repeat center;`}
    background-color: ${props => `${props.color}` || "#fff"};
    background-size:cover;
  }
`

const StyledContent = styled.div`
  && {
    padding: 3rem 0;
    ${props => props.center && "text-align:center"}
  }
`

/**
 * 
 HorizontalBlocksBlock{
    list{
          name
          content
          button
          link
          color
          image
        }
      color
      center
      image
 }

 */

export const HorizontalBlocksBlock = {
  label: "Horizontal Blocks",
  name: "HorizontalBlocksBlock",
  key: "HorizontalBlocksBlock",
  defaultItem: {
    HorizontalBlocksBlock: {
      list: [
        {
          name: "",
          content: "",
          button: "",
          link: "",
          color: "",
          image: {},
        },
      ],
      color: "",
      center: false,
      image: {},
    },
  },
  fields: [
    {
      label: "Block List",
      name: "HorizontalBlocksBlock.list",
      component: "group-list",
      description: "Blocks List",
      itemProps: item => ({
        key: item.id,
        label: item.name,
      }),
      defaultItem: () => ({
        name: "New Block",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Button",
          name: "button",
          component: "text",
        },
        {
          label: "Link",
          name: "link",
          component: "text",
        },

        {
          name: "color",
          label: "Background Color",
          component: "color",
          colorFormat: "hex",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
      ],
    },
    {
      name: "HorizontalBlocksBlock.center",
      label: "Center",
      component: "toggle",
    },
    {
      name: "HorizontalBlocksBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      label: "Background Image",
      name: "HorizontalBlocksBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    //...
  ],
}
