import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Leaflet from "leaflet"
import axios from "axios"
import { Wrapper } from "../components/style"
import { Row, Col } from "react-flexbox-grid"
import { Map, Marker, Popup, TileLayer, GeoJSON } from "react-leaflet"
import "../../node_modules/leaflet/dist/leaflet.css"
export function MapComponent({ data }) {
  const position = [38.719637, -9.395666]

  const pointToLayerElements = props => {
    return Leaflet.circleMarker(
      [props.geometry.coordinates[1], props.geometry.coordinates[0]],
      { radius: 2, draggable: props.editElements }
    )
  }

  const [geoJson, setShape] = useState(null)

  useEffect(() => {
    const fetchMarker = async () => {
      const result = await Promise.all([
        axios("/map/marker.json"),
        axios("/map/dist.json"),
      ])
      setShape({ marker: result[0].data, shape: result[1].data })
    }
    if (!geoJson) fetchMarker()
  }, [geoJson])

  const url = typeof window !== "undefined" ? window.location.href : ""
  let lang = ""
  if (url.match(/\/en$/) || url.match(/\/en\//)) {
    lang = "/en"
  }

  if (!geoJson) return null
  return (
    <>
      <MapWrapper>
        <Map center={position} zoom={3} scrollWheelZoom={false}>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          <GeoJSON
            data={geoJson.shape}
            style={{
              fillColor: "#d98b0f",
              weight: 5,
              color: "#d98b0f",
            }}
          />
          <GeoJSON
            data={geoJson.marker}
            pointToLayer={pointToLayerElements}
            style={{
              fillColor: "#114F68",
              color: "#114F68",
            }}
          />
        </Map>
      </MapWrapper>
      <Wrapper>
        <Row>
          <Col xs={6} sm={6} md={6}>
            <MapLabel>
              <MapLabelColor color="#d98b0f" />
              <MapLabelText>
                {lang === "/en"
                  ? "Meagre Geographical distribution"
                  : "Área de distribuição da corvina-legítima"}
              </MapLabelText>
            </MapLabel>
          </Col>
          <Col xs={6} sm={6} md={6}>
            <MapLabel>
              <MapLabelColor color="#114F68" />
              <MapLabelText>
                {lang === "/en" ? "Fishbase records" : "Registos FishBase"}
              </MapLabelText>
            </MapLabel>
          </Col>
        </Row>
      </Wrapper>
    </>
  )
}

const MapLabel = styled.div`
  && {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
`

const MapLabelColor = styled.div`
  && {
    height: 20px;
    width: 10%;
    background: ${props => (props.color ? props.color : "#000")};
  }
`

const MapLabelText = styled.div`
  && {
    margin-left: 3%;
    font-size: 0.7em;
  }
`

const MapWrapper = styled.div`
  && {
    height: 600px;
    margin: 3em 0;
    .leaflet-container {
      height: 100%;
      .extra-marker i {
        font-size: 17px;
      }

      .detailsWraper {
        padding: 1em;
        min-width: 200px;
        .element-image {
          height: 180px;
          background-position: center;
          background-size: cover;
          margin: -1.6em -1.6em 1em;
        }
        span {
          font-weight: bold;
        }
        h3 {
          padding: 0;
          margin: 0;
          font-size: 1.3em;
          color: #4da1ff;
          font-weight: normal;
        }
        p {
          padding: 0;
          margin: 0 0 0.8em 0;
          font-size: 1em;
          color: #4a4a4a;
          font-weight: normal;
        }
        ul {
          padding: 0;
          margin: 0;
          font-size: 1em;
          color: #555;
          li {
            list-style-type: none;
          }
        }
      }
    }
  }
`

export const MapComponentBlock = {
  label: "Map",
  name: "MapComponentBlock",
  key: "MapComponentBlock",
  defaultItem: { MapComponentBlock: { show: true } },
  fields: [],
}
