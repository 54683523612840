import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Main } from "./style"
import { SEO } from "./Settings/seo"
import { ThemeContext } from "./Settings/theme"
import { Hero } from "./hero"
import { removeNull } from "./helpers"
import { NavForm } from "./Structure/nav"
import { ThemeForm } from "./Settings/theme"
import get from "lodash.get"

import { useGlobalJsonForm } from "gatsby-tinacms-json"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const merge = require("lodash.merge")

export const PageLayout = ({ page = {}, children }) => {
  const data = useStaticQuery(graphql`
    query PageLayoutQuery {
      nav: settingsJson(
        fileRelativePath: { eq: "/content/settings/menu.json" }
      ) {
        ...nav

        rawJson
        fileRelativePath
      }
      theme: settingsJson(
        fileRelativePath: { eq: "/content/settings/theme.json" }
      ) {
        ...globalTheme

        rawJson
        fileRelativePath
      }
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        logo {
          publicURL
        }
        title
        description
        author
        rawJson
        fileRelativePath
      }
    }
  `)

  useGlobalJsonForm(data.nav, NavForm)
  useGlobalJsonForm(data.theme, ThemeForm)
  useGlobalJsonForm(data.site, SiteForm)

  const themeContext = React.useContext(ThemeContext)
  const theme = themeContext.theme
  const pageTitle =
    page && page.title
      ? page.title
      : page && page.frontmatter && page.frontmatter.title
      ? page.frontmatter.title
      : ""
  let pageHero
  if (page) {
    pageHero = page.frontmatter ? page.frontmatter.hero : page.hero
  } else {
    pageHero = null
  }
  const hero = pageHero
    ? merge({}, theme.hero, removeNull(pageHero))
    : theme.hero

  //titleBackground

  return (
    <>
      {pageTitle && <SEO title={pageTitle} />}
      <div />
      <Hero hero={hero}></Hero>
      <Main>{children}</Main>
    </>
  )
}

const SiteForm = {
  label: "Site",
  fields: [
    {
      label: "Logo",
      name: "rawJson.logo",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    {
      label: "Fixed Logo",
      name: "rawJson.fixedlogo",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },

    {
      label: "Title",
      name: "rawJson.title",
      component: "text",
      parse(value) {
        return value || ""
      },
    },
    {
      label: "Description",
      name: "rawJson.description",
      component: "text",
      parse(value) {
        return value || ""
      },
    },
    {
      label: "Footer",
      name: "rawJson.footer",
      component: "group",
      fields: [
        {
          label: "Title",
          name: "title",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Content",
          name: "Content",
          component: "html",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Partners List",
          name: "list",
          component: "group-list",
          description: "Icon List",
          itemProps: item => ({
            key: item.id,
            label: item.label,
          }),
          defaultItem: () => ({
            label: "New Block",
            id: Math.random()
              .toString(36)
              .substr(2, 9),
          }),
          fields: [
            {
              label: "Title",
              name: "label",
              component: "text",
            },
            {
              label: "Image",
              name: "image",
              component: "image",
              parse: filename => `../images/${filename}`,
              uploadDir: () => `/content/images/`,
              previewSrc: (formValues, fieldProps) => {
                const pathName = fieldProps.input.name.replace(
                  "rawJson",
                  "jsonNode"
                )
                const imageNode = get(formValues, pathName)
                if (!imageNode) return ""
                return imageNode.publicURL
              },
            },
          ],
        },
        {
          name: "color",
          label: "Background Color",
          component: "color",
          colorFormat: "hex",
        },
        {
          label: "Background Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
        {
          name: "_",
          component: () => (
            <form
              action={
                "https://jenkins.corsdigital.com/generic-webhook-trigger/invoke"
              }
              target="_blank"
              method="POST"
            >
              <input type="hidden" value="MIG54csd%IGm^c6G" name="token" />

              <button className={"button action"}>
                Deploy this website version
              </button>
            </form>
          ),
        },
      ],
    },
  ],
}
