import React, { useState } from "react"
import styled from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import Slider from "react-slick"

export function ContentSlideshow({ data, html, first }) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    autoplay: data.list.length > 3,
    slidesToShow: data.list.length > 3 ? 3 : data.list.length,
    afterChange: index => {
      setImage(index + 1)
    },
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          autoplay: data.list.length > 2 ? 2 : data.list.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: data.list.length > 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [image, setImage] = useState(0)
  let images = data.list.map(d => d.image)
  return (
    <StyledBackground background={images[image] && images[image].publicURL}>
      <ReadSafe />
      <Wrapper>
        <SectionTitle>
          <h2>{data.name}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          />
        </SectionTitle>

        <StyledContent background={data.background} center={data.center}>
          <Slider {...settings}>
            {data.list.map((block, i) => {
              return (
                <StyledBlock
                  key={i}
                  xs
                  onMouseOver={() => setImage(i)}
                  onFocus={() => setImage(i)}
                >
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: block.name,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: block.content,
                    }}
                  />
                  {block.link && block.button && (
                    <a className={"button"} href={block.link}>
                      {block.button}
                    </a>
                  )}
                </StyledBlock>
              )
            })}
          </Slider>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}

const SectionTitle = styled.div`
  && {
    color: #fff;
    text-align: center;
    h2 {
      margin: 0;
    }
    div {
      font-size: 0.8em;
    }
  }
`

const ReadSafe = styled.div`
  && {
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    );
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`

const StyledBlock = styled.div`
  && {
    padding: 2em;
    margin: 0 1em;
    height: 400px;
    display: table-cell !important;
    vertical-align: bottom;
    h3 {
      font-size: 1.2em;
      margin: 0.5em 0;
      color: ${props => props.theme.color.white};
    }
    div {
      display: none;
      font-size: 0.7em;
      margin: 0;
      opacity: 0.8;
      color: ${props => props.theme.color.white};
    }
    .button {
      display: none;
      font-size: 0.8em;
      text-decoration: none;
      color: ${props => props.theme.color.white};
    }
    &:hover {
      div,
      .button {
        display: block;
      }
    }
  }
`

const StyledBackground = styled.div`
  && {
    margin: 0;

    position: relative;
    background-color: ${props => props.theme.color.secondary};
    ${props =>
      props.background ? `background-image:url('${props.background}')` : ""};
    transition: background-image 1s ease-in-out;
    background-size: cover;
    padding: 2em;
    min-height: 600px;
  }
`

const StyledContent = styled.div`
  && {
    padding: 3rem 0 1em 0;
    ${props => props.center && "text-align:center"}
    .slick-slide {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`

/**
ContentSlideshowBlock {
  name
  content
  list{
    name
    content
    button
    link
    image {
      publicURL
    }
  }
}
 */

export const ContentSlideshowBlock = {
  label: "Content Slideshow",
  name: "ContentSlideshowBlock",
  key: "ContentSlideshowBlock",
  defaultItem: {
    ContentSlideshowBlock: {
      name: "",
      content: "",
      list: [
        {
          name: "",
          content: "",
          button: "",
          link: "",
          image: {},
        },
      ],
    },
  },
  fields: [
    {
      label: "Title",
      name: "ContentSlideshowBlock.name",
      component: "text",
    },
    {
      label: "Content",
      name: "ContentSlideshowBlock.content",
      component: "html",
    },
    {
      label: "Content List",
      name: "ContentSlideshowBlock.list",
      component: "group-list",
      description: "Content List",
      itemProps: item => {
        return {
          key: item.id,
          label: item.name,
        }
      },
      defaultItem: () => ({
        name: "New Content",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Button",
          name: "button",
          component: "text",
        },
        {
          label: "Link",
          name: "link",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
      ],
    },
    //...
  ],
}
