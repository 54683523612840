import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import { Row, Col } from "react-flexbox-grid"

export function ContentHighlight({ data, first }) {
  return (
    <ContentWrapper>
      <Wrapper>
        <Row>
          <Col md={4}>
            <StyledSideContent
              data-sal={!first && "slide-up"}
              data-sal-delay={300}
              data-sal-easing="ease"
              data-sal-duration="600"
              dangerouslySetInnerHTML={{
                __html: data.side,
              }}
            ></StyledSideContent>
          </Col>
          <Col md={8}>
            <StyledContent
              data-sal={!first && "slide-up"}
              data-sal-delay={350}
              data-sal-easing="ease"
              data-sal-duration="600"
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            ></StyledContent>
          </Col>
        </Row>
      </Wrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  && {
    margin: 2em;
  }
`

const StyledSideContent = styled.div`
  && {
    font-size: 0.8em;
    padding: 3em;
    background: ${props => props.theme.color.primary};
    color: #fff;
    border-radius: 2em;
    h3 {
      font-size: 1.6em;
    }
  }
`

const StyledContent = styled.div`
  padding: 2em;
  font-size: 0.9em;
  color: ${props => props.theme.color.primary};
  ${props =>
    props.collumns &&
    css`
      column-count: ${props.collumns};
    `};
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`

/**
 * 
  ContentHighlightBlock {
          content
          side
        }
 */

export const ContentHighlightBlock = {
  label: "Content Highlight",
  name: "ContentHighlightBlock",
  key: "ContentHighlightBlock",
  defaultItem: {
    ContentHighlightBlock: {
      content: "",
      side: "",
    },
  },
  fields: [
    {
      name: "ContentHighlightBlock.content",
      label: "Content",
      component: "html",
    },
    {
      name: "ContentHighlightBlock.side",
      label: "Side Content",
      component: "html",
    },
  ],
}
