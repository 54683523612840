import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"

function calculatePrecentageCircle(number) {
  const circumference = 565.4866
  return -circumference * (Number(number) / 100) + circumference
}

const RoundGraph = ({ title, content, number }) => {
  return (
    <>
      <div className="item html">
        <div className={"lable-content"}>
          <h2>{title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
          <g>
            <title>{title}</title>
            <circle
              className="circle_animation_bottom"
              r="90"
              cy="100"
              cx="100"
              strokeWidth="5"
              fill="none"
            />
            <circle
              className="circle_animation"
              style={{
                strokeDashoffset: calculatePrecentageCircle(Number(number)),
              }}
              r="90"
              cy="100"
              cx="100"
              strokeWidth="11"
              fill="none"
            />
          </g>
        </svg>
      </div>
    </>
  )
}

export function Graphs({ data, html, first }) {
  if (!data) return null
  return (
    <StyledBackground
      color={data.color}
      background={data.image && data.image.publicURL}
    >
      <Wrapper>
        <StyledContent background={data.background} center={data.center}>
          {data.name && (
            <h2
              data-sal={!first && "slide-up"}
              data-sal-delay={300}
              data-sal-easing="ease"
              data-sal-duration="600"
            >
              {data.name}
            </h2>
          )}
          <RoundGraphStyled>
            {data.list &&
              data.list.map((block, i) => {
                return (
                  <StyledBlock
                    key={i}
                    xs
                    text={
                      data.image || data.color || block.image || block.color
                        ? "white"
                        : "black"
                    }
                    data-sal={!first && "slide-up"}
                    data-sal-delay={300 + (i + 1) * 50}
                    data-sal-easing="ease"
                    data-sal-duration="600"
                    image={block.image}
                    color={block.color}
                  >
                    <RoundGraph
                      title={block.name}
                      content={block.content}
                      number={Number(block.number)}
                    />
                  </StyledBlock>
                )
              })}
          </RoundGraphStyled>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}

const RoundGraphStyled = styled(Row)`
  && {
    .item {
      position: relative;
      display: inline-block;
      .lable-content {
        text-align: center;
        position: absolute;
        width: 80%;
        margin: 3em 10%;
        font-size: 0.9em;
        h2 {
          font-size: 2.3em;
          text-align: center;
          line-height: 1;
          margin-bottom: 0.4em;
          font-weight: bold;
          color: ${props => props.theme.color.white};
        }
        p {
          margin: 0;
          font-size: 0.8em;
          color: ${props => props.theme.color.white};
          opacity: 0.7;
        }
      }
    }
    .big-number {
      padding: 7em 4em;
      text-align: center;
      @media (max-width: 992px) {
        padding: 4em 1em;
      }
      h4 {
        color: #fff;
        font-size: 4em;
        font-weight: normal;
        @media (max-width: 992px) {
          font-size: 3em;
        }
      }
      p {
        color: #fff;
        font-size: 1.5em;
      }
    }

    svg {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    .circle_animation_bottom {
      stroke: ${props => props.theme.color.primary};
    }

    .circle_animation {
      stroke: ${props => props.theme.color.white};
    }

    .circle_animation {
      stroke-dasharray: 565.4866; /* this value is the pixel circumference of the circle */
    }

    .html .circle_animation {
      -webkit-animation: html 1s ease-out forwards;
      animation: html 1s ease-out forwards;
    }
  }
`

const StyledBlock = styled(Col)`
  && {
    padding: 2em;
    margin: 0 1em;
      ${props =>
        props.color || props.image
          ? css`
              box-shadow: 0 0.5rem 0.5rem 0rem rgba(41, 43, 45, 0.2);
            `
          : ""}
      ${props => props.color && `background-color:${props.color};`}
      ${props =>
        props.image &&
        css`
      background: url('${props.image.publicURL}') center no-repeat; 
      background-size:cover;
      `}
  
  }
`

const StyledBackground = styled.div`
  && {
    position: relative;
    ${props =>
      props.background &&
      `background: url('${props.background}') no-repeat center;`}
    background-color: ${props => `${props.color}` || "#fff"};
    background-size:cover;
  }
`

const StyledContent = styled.div`
  && {
    padding: 3rem 0;
    text-align: center;
    h2 {
      text-align: center;
      color: #fff;
    }
  }
`

/**
GraphsBlock {
   name
    list
      {
        name
        content
        number
      }
    color
    image,
}
 */

export const GraphsBlock = {
  label: "Graphs",
  name: "GraphsBlock",
  key: "GraphsBlock",
  defaultItem: {
    GraphsBlock: {
      name: "",
      list: [
        {
          name: "",
          content: "",
          number: "",
        },
      ],
      color: "",
      image: {},
    },
  },
  fields: [
    {
      label: "Title",
      name: "GraphsBlock.name",
      component: "text",
    },
    {
      label: "Number List",
      name: "GraphsBlock.list",
      component: "group-list",
      description: "Number List",
      itemProps: item => ({
        key: item.id,
        label: item.name,
      }),
      defaultItem: () => ({
        name: "New Number",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Percentage",
          name: "number",
          component: "text",
        },
      ],
    },
    {
      name: "GraphsBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      label: "Background Image",
      name: "GraphsBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    //...
  ],
}
