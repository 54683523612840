import React, { useState } from "react"
// import { query } from "./query"
import { graphql } from "gatsby"
// import { Form, FormBlock } from "../blocks/form"
import { Title, TitleBlock } from "../blocks/title"
import { Media, MediaBlock } from "../blocks/media"
import { FileList, FileListBlock } from "../blocks/fileList"
import { Masonry, MasonryBlock } from "../blocks/masonry"
import { GridSlideshow, GridSlideshowBlock } from "../blocks/gridSlideshow"
import { ImageText, ImageTextBlock } from "../blocks/imageText"
import { Graphs, GraphsBlock } from "../blocks/graphs"
import { IconRows, IconRowsBlock } from "../blocks/iconRows"
import { MapComponent, MapComponentBlock } from "../blocks/map"
import { SpacerBlock } from "../blocks/spacer"
import {
  ContentSidebySide,
  ContentSidebySideBlock,
} from "../blocks/contentSidebySide"
import {
  ContentHighlight,
  ContentHighlightBlock,
} from "../blocks/contentHighlight"
import {
  ContentSlideshow,
  ContentSlideshowBlock,
} from "../blocks/contentSlideshow"
import {
  HorizontalBlocks,
  HorizontalBlocksBlock,
} from "../blocks/horizontalBlocks"
import {
  HorizontalTitleText,
  HorizontalTitleTextBlock,
} from "../blocks/horizontalTitleText"
import { Content, ContentBlock } from "../blocks/content"
import { PageLayout } from "../components/pageLayout"

import { useLocalJsonForm } from "gatsby-tinacms-json"

export default function Page({ data }) {
  const [pageUrl, setPageUrl] = useState(false)

  const [page] = useLocalJsonForm(data.page, PageForm)
  const blocks = page && page.blocks ? page.blocks : []

  if (
    typeof window !== "undefined" &&
    data.page &&
    pageUrl !== data.page.fileRelativePath
  ) {
    setPageUrl(data.page.fileRelativePath)
    window.scrollTo(0, 0)
  }

  return (
    <PageLayout page={page}>
      {blocks &&
        blocks.map(({ _template, ...data }, i) => {
          if (!data[_template]) return null
          let defaultProps = {
            key: i,
            id: i,
            first: i === 0,
            data: data[_template],
            odd: i % 2,
          }

          switch (_template) {
            case "GridSlideshowBlock":
              return <GridSlideshow page={page} {...defaultProps} />
            case "IconRowsBlock":
              return <IconRows {...defaultProps} />
            case "TitleBlock":
              return <Title {...defaultProps} />
            case "ImageTextBlock":
              return <ImageText {...defaultProps} />
            case "ContentSidebySideBlock":
              return <ContentSidebySide {...defaultProps} />
            case "GraphsBlock":
              return <Graphs {...defaultProps} />
            case "ContentHighlightBlock":
              return <ContentHighlight {...defaultProps} />
            case "MapComponentBlock":
              return (
                typeof window !== "undefined" && (
                  <MapComponent {...defaultProps} />
                )
              )
            case "FileListBlock":
              return <FileList {...defaultProps} />
            case "MediaBlock":
              return <Media {...defaultProps} />
            // case "FormBlock":
            //   return <Form form={data} {...defaultProps} />
            case "MasonryBlock":
              return <Masonry {...defaultProps} />
            case "HorizontalBlocksBlock":
              return <HorizontalBlocks {...defaultProps} />
            case "ContentSlideshowBlock":
              return <ContentSlideshow {...defaultProps} />
            case "HorizontalTitleTextBlock":
              return <HorizontalTitleText {...defaultProps} />
            case "ContentBlock":
              return <Content {...defaultProps} />
            default:
              return true
          }
        })}
    </PageLayout>
  )
}

const templates = {
  TitleBlock,
  MediaBlock,
  MasonryBlock,
  ImageTextBlock,
  GridSlideshowBlock,
  ContentSidebySideBlock,
  GraphsBlock,
  SpacerBlock,
  MapComponentBlock,
  FileListBlock,
  ContentHighlightBlock,
  IconRowsBlock,
  // FormBlock,
  ContentBlock,
  HorizontalBlocksBlock,
  HorizontalTitleTextBlock,
  ContentSlideshowBlock,
}

const sortObjectKeys = obj =>
  Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key]
      return acc
    }, {})

const PageForm = {
  label: "Page",
  fields: [
    {
      label: "Title",
      name: "rawJson.title",
      component: "text",
    },
    {
      label: "Hero",
      name: "rawJson.hero",
      component: "group",
      fields: [
        {
          label: "Headline",
          name: "headline",
          component: "text",
        },
        {
          label: "Textline",
          name: "textline",
          component: "text",
        },
        {
          label: "Video",
          name: "video",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: formValues => {
            if (!formValues.jsonNode.hero || !formValues.jsonNode.hero.image)
              return ""
            return formValues.jsonNode.hero.image.publicURL
          },
        },
        {
          label: "Actions",
          name: "ctas",
          component: "group-list",
          itemProps: item => ({
            key: item.link,
            label: item.label,
          }),
          fields: [
            {
              label: "Label",
              name: "label",
              component: "text",
            },
            {
              label: "Link",
              name: "link",
              component: "text",
            },
            {
              label: "Primary",
              name: "primary",
              component: "toggle",
            },
            {
              label: "Arrow",
              name: "arrow",
              component: "toggle",
            },
          ],
        },
        {
          label: "Large",
          name: "large",
          component: "toggle",
        },
      ],
    },
    {
      label: "Page Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: sortObjectKeys(templates),
    },
  ],
}

export const pageQuery = graphql`
  query($path: String!) {
    page: pagesJson(path: { eq: $path }) {
      title
      displayTitle
      hero {
        headline
        textline
        large
        overlay
        video
        image {
          publicURL
        }
        ctas {
          label
          link
          primary
          arrow
        }
      }
      blocks {
        _template
        ContentBlock {
          text
          number
          center
        }
        ContentHighlightBlock {
          content
          side
        }
        ContentSidebySideBlock {
          content
          side
          button
          link
        }
        ContentSlideshowBlock {
          name
          content
          list {
            name
            content
            button
            link
            image {
              publicURL
            }
          }
        }
        FileListBlock {
          list {
            title
            url
            file {
              publicURL
            }
          }
        }
        GraphsBlock {
          name
          list {
            name
            content
            number
          }
          color
          image {
            publicURL
          }
        }
        GridSlideshowBlock {
          list {
            name
            content
            link
            label
            image {
              publicURL
            }
          }
        }
        HorizontalBlocksBlock {
          list {
            name
            content
            button
            link
            color
            image {
              publicURL
            }
          }
          color
          center
          image {
            publicURL
          }
        }
        MapComponentBlock {
          show
        }
        HorizontalTitleTextBlock {
          content
          title
          color
          button
          link
          image {
            publicURL
          }
        }
        IconRowsBlock {
          color
          backgroundType
          center
          list {
            name
            content
            button
            link
            color
            image {
              publicURL
            }
          }
        }
        MediaBlock {
          video
          full
          image {
            publicURL
          }
        }
        ImageTextBlock {
          content
          title
          button
          link
          video
          newWindow
          image {
            publicURL
          }
          label
          color
          contentBackground
        }
        MasonryBlock {
          list {
            title
            content
            button
            link
            color
            image {
              publicURL
            }
          }
          background
          color
          shadow
          center
          position
          number
          image {
            publicURL
          }
        }
        TitleBlock {
          title
          center
          underline
        }
      }
      rawJson
      fileRelativePath
    }
  }
`
