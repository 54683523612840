import React from "react"
import styled from "styled-components"
import { Wrapper } from "../components/style"
import { transparentize } from "polished"

export function FileList({ data }) {
  return (
    <Wrapper>
      <FileListWrapper>
        <ul>
          {data.list &&
            data.list.map(item => {
              return (
                item.file && (
                  <li>
                    <a
                      href={item.url || item.file.publicURL || item.file}
                      target={"_blank"}
                    >
                      {item.title}
                    </a>
                  </li>
                )
              )
            })}
        </ul>
      </FileListWrapper>
    </Wrapper>
  )
}

const FileListWrapper = styled.div`
  && {
    margin: 0 0 3em 0;
    padding: 2em;
    border-top: 7px solid ${props => props.theme.color.secondary};
    background: ${props => transparentize(0.95, props.theme.color.secondary)};
    ul {
      li {
        display: inline-block;
        width: 45%;
        a {
          text-decoration: none;
        }
      }
    }
  }
`

/**
FileListBlock {
  list{
    title
    url
    file
  }
}
 */

export const FileListBlock = {
  label: "File List",
  name: "FileListBlock",
  key: "FileListBlock",
  defaultItem: {
    FileListBlock: {
      list: [
        {
          title: "",
          url: "",
          file: "",
        },
      ],
    },
  },
  fields: [
    {
      label: "File List",
      name: "FileListBlock.list",
      component: "group-list",
      description: "File List",
      itemProps: item => ({
        key: item.id,
        label: item.title,
      }),
      defaultItem: () => ({
        title: "New File",
        url: "",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "title",
          component: "text",
        },
        {
          label: "Url",
          name: "url",
          component: "text",
        },
        {
          name: "file",
          component: "file",
          label: "File",
          parse: file => `../uploads/${file}`,
          uploadDir: () => "/content/uploads",
        },
      ],
    },
  ],
}
