import React from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"
import ReactPlayer from "react-player"

export function ImageText({ data, odd, html, first }) {
  return (
    <StyledBackground
      color={data.color}
      contentBackground={data.contentBackground}
    >
      <Wrapper>
        <StyledContent odd={odd} contentBackground={data.contentBackground}>
          <Row>
            <Col className={"content-image"} md={6}>
              {data.video ? (
                <ReactPlayer
                  url={data.video}
                  className={"react-player"}
                  width="100%"
                  height="100%"
                  responsive={true}
                />
              ) : (
                data.image && (
                  <div
                    className={"image-wrap"}
                    data-sal={!first && "slide-up"}
                    data-sal-delay="150"
                    data-sal-easing="ease"
                    data-sal-duration="600"
                  >
                    <img
                      src={data.image.publicURL || data.image}
                      alt={data.title}
                    />

                    {data.label && (
                      <ImageLabel
                        className={"ImageLabel"}
                        data-sal={!first && "slide-up"}
                        data-sal-delay="150"
                        data-sal-easing="ease"
                        data-sal-duration="600"
                        dangerouslySetInnerHTML={{
                          __html: data.label,
                        }}
                      />
                    )}
                  </div>
                )
              )}
            </Col>
            <Col className={"content-wrap"} md={6}>
              <ContentWrap
                className={"content"}
                odd={odd}
                contentBackground={data.contentBackground}
              >
                <div>
                  <h2
                    data-sal={!first && "slide-up"}
                    data-sal-delay="300"
                    data-sal-easing="ease"
                    data-sal-duration="600"
                    dangerouslySetInnerHTML={{
                      __html: data.title,
                    }}
                  />
                  <div
                    data-sal={!first && "slide-up"}
                    data-sal-delay="350"
                    data-sal-easing="ease"
                    data-sal-duration="600"
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                  {data.button && (
                    <ActionButton
                      data-sal={!first && "slide-up"}
                      data-sal-delay="400"
                      data-sal-easing="ease"
                      data-sal-duration="600"
                      href={data.link}
                      color={data.color}
                      target={data.newWindow ? "_blank" : "_self"}
                    >
                      {data.button}
                    </ActionButton>
                  )}
                </div>
              </ContentWrap>
            </Col>
          </Row>
        </StyledContent>
      </Wrapper>
    </StyledBackground>
  )
}
const ContentWrap = styled.div`
  && {
    border-radius: 2em;
    ${props =>
      props.contentBackground
        ? css`
            background: ${props.contentBackground};
            color: ${props.theme.color.white};
            padding: 7em 4em;
            margin: 2em -4em 0 0;
            h2,
            p {
              color: ${props.theme.color.white};
            }
            a {
              background: ${props.theme.color.white};
              color: ${props.contentBackground};
            }
          `
        : ``};
    transform: skewY(${props => (props.odd ? "-1deg" : "1deg")});
    > div {
      transform: skewY(${props => (props.odd ? "1deg" : "-1deg")});
    }
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      ${props =>
        props.contentBackground
          ? css`
              margin: -5em 0 0 0 !important;
            `
          : css`
              margin: 0 0 0 0 !important;
              text-align: center;
            `}
    }
  }
`
const ImageLabel = styled.div`
  background: ${props => props.theme.color.primary};
  border-radius: 1em;
  width: 70%;
  color: #fff !important;
  font-size: 0.8em !important;
  padding: 1em;
  position: absolute;
  bottom: -1em;
  right: 0;
`

const ActionButton = styled.a`
  && {
    background: ${props => props.theme.color.primary};
    color: #fff;
    font-size: 0.8em;
    text-decoration: none;
    padding: 0.5em 1.5em;
    border-radius: ${props => props.theme.radius.small};
    ${props =>
      props.color
        ? css`
            background: #fff;
            color: ${props => props.theme.color.primary};
          `
        : ``};
  }
`
const StyledBackground = styled.div`
  && {
    padding: 2em 0;
    position: relative;
    background-size: cover;
    background-color: ${props => props.color || "#fff"};
    ${props =>
      props.color
        ? css`
            h2,
            div {
              color: #fff;
            }
          `
        : ``};
  }
`

const StyledContent = styled.div`
  padding: 3rem 0;
  .content-wrap {
    vertical-align: middle;
    justify-content: center;
    display: flex;
    order: 0;
    align-items: center;
    padding: 2em;
  }
  .content-image {
    order: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .image-wrap{
  
      img{
        border-radius:3em;
      }
  }
  ${props =>
    props.odd
      ? css`
          .content-wrap {
            order: 1;
            @media (max-width: ${props => props.theme.breakpoints.small}) {
              order: 1;
            }
          }
          .content-image {
            order: 0;
            @media (max-width: ${props => props.theme.breakpoints.small}) {
              order: 0;
            }
          }
        `
      : css`
          .content-image {
            order: 1;
            @media (max-width: ${props => props.theme.breakpoints.small}) {
              order: 0;
            }
          }
          .content-wrap {
            order: 0;
            @media (max-width: ${props => props.theme.breakpoints.small}) {
              order: 1;
            }
          }
        `}

  ${props =>
    props.contentBackground && !props.odd
      ? css`
          .content {
            margin: 0 -4em 0 0;
          }
        `
      : ""}

  ${props => {
    return props.contentBackground && props.odd === 1
      ? css`
          .content {
            margin: 0 0 0 -5em;
          }
        `
      : ""
  }}
  h2 {
    color: ${props => props.theme.color.primary};
    font-size: 2.6em;
  }
  div {
    color: ${props => props.theme.color.black};
    font-size: 0.95em;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
      margin: 0 !important;
    }
`

/**
 * 
  ImageTextBlock{
      content
      title
      button
      link
      image{
        publicURL
      }
      label
      color
      contentBackground
    }
 */

export const ImageTextBlock = {
  label: "Image Text",
  name: "ImageTextBlock",
  key: "ImageTextBlock",
  defaultItem: {
    ImageTextBlock: {
      content: "",
      title: "",
      button: "",
      link: "",
      video: "",
      image: "",
      label: "",
      color: "",
      newWindow: false,
      contentBackground: "",
    },
  },
  fields: [
    { name: "ImageTextBlock.title", label: "Title", component: "text" },
    { name: "ImageTextBlock.content", label: "Content", component: "html" },
    { name: "ImageTextBlock.button", label: "Button", component: "text" },
    { name: "ImageTextBlock.link", label: "Link", component: "text" },
    {
      name: "ImageTextBlock.newWindow",
      label: "Open new Window",
      component: "toggle",
    },
    {
      label: "Image",
      name: "ImageTextBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    {
      label: "Video",
      name: "ImageTextBlock.video",
      component: "text",
    },
    { name: "ImageTextBlock.label", label: "label", component: "text" },
    {
      name: "ImageTextBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      name: "ImageTextBlock.contentBackground",
      label: "Content Background Color",
      component: "color",
      colorFormat: "hex",
    },
  ],
}
