import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import { Row, Col } from "react-flexbox-grid"
import { default as MLayout } from "react-masonry-css"
// import Lightbox from "react-image-lightbox"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

export function Masonry({ data, html }) {
  const [photoIndex, setImage] = useState(0)
  const [isOpen, setOpen] = useState(false)

  if (!data) return null

  const breakpointColumnsObj = {
    default: Number(data.number) || 4,
    700: (Number(data.number) || 4) / 2,
    500: 1,
  }

  const images = data.list.map(b => b.image && b.image.publicURL)

  return (
    <StyledBackground
      color={data.color}
      background={data.image && data.image.publicURL}
    >
      <Wrapper>
        <StyledContent background={data.background} center={data.center}>
          <StyledRow>
            <MLayout
              breakpointCols={breakpointColumnsObj}
              className="masonry-grid"
              columnClassName="masonry-grid_column"
            >
              {data.list &&
                data.list.map((block, i) => {
                  return (
                    <StyledBlock
                      key={i}
                      xs
                      shadow={data.shadow ? "true" : "false"}
                      position={data.position ? "true" : "false"}
                      text={
                        data.image || data.color || block.image || block.color
                          ? "white"
                          : "black"
                      }
                      image={block.image}
                      color={block.color}
                      onClick={() => {
                        if (!block.link) {
                          setImage(i)
                          setOpen(true)
                        }
                      }}
                    >
                      {data.position && block.image && (
                        <img
                          className={"image-element-class"}
                          alt={block.name || ""}
                          src={block.image.publicURL}
                        />
                      )}
                      {(block.name || block.content || block.link) && (
                        <div className={"contentWrap"}>
                          {block.name && (
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: block.name,
                              }}
                            />
                          )}
                          {block.content && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: block.content,
                              }}
                            />
                          )}
                          {block.link && block.button && (
                            <a className={"button"} href={block.link}>
                              {block.button}
                            </a>
                          )}
                        </div>
                      )}
                    </StyledBlock>
                  )
                })}
            </MLayout>
          </StyledRow>
        </StyledContent>
      </Wrapper>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setImage((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setImage((photoIndex + 1) % images.length)}
        />
      )}
    </StyledBackground>
  )
}

const StyledRow = styled(Row)`
  && {
    align-items: stretch;
    .masonry-grid {
      display: -webkit-box; /* Not needed if autoprefixing */
      display: -ms-flexbox; /* Not needed if autoprefixing */
      display: flex;
      margin-left: -30px; /* gutter size offset */
      width: 100%;
    }
    .masonry-grid_column {
      padding-left: 30px; /* gutter size */
      background-clip: padding-box;
    }

    /* Style your items */
    .masonry-grid_column > div {
      /* change div to reference your elements you put in <Masonry> */
      margin-bottom: 30px;
    }
    .image-element-class {
      cursor: pointer;
      img {
        max-width: 100%;
        filter: grayscale(50%);
        @media (max-width: 768px) {
          filter: none;
        }
        &:hover {
          text-align: center;
          filter: none;
        }
      }
    }
  }
`

const StyledBlock = styled(Col)`
  && {
      ${props =>
        props.shadow === "true"
          ? css`
              box-shadow: 0 0.5rem 0.5rem 0rem rgba(41, 43, 45, 0.2);
            `
          : ""}
      ${props => props.color && `background-color:${props.color};`}
      ${props =>
        props.image &&
        props.position === "false" &&
        css`
        background: url('${props.image.publicURL}') center no-repeat; 
        background-size:cover;
      `}
    .contentWrap{
        padding: 2em;

      }
    h3 {
      font-size: 1.2em;
      margin: .5em 0;
      color: ${props =>
        props.text === "black"
          ? props.theme.color.black
          : props.theme.color.white};
    }
    img{
      display:inline-block;
      margin:0 ;
    }
    div {
      font-size: 0.8em;
      margin: 0;
      opacity: 0.8;
      color: ${props =>
        props.text === "black"
          ? props.theme.color.black
          : props.theme.color.white};
    }
    .button {
      font-size: 0.8em;
      text-decoration: none;
      padding: 0.5em 2em;
      border-radius: ${props => props.theme.radius.small};
      background-color: ${props =>
        props.text === "black"
          ? props.theme.color.primary
          : props.theme.color.white};
      color: ${props =>
        props.text === "white"
          ? props.theme.color.primary
          : props.theme.color.white};
    }
  }
`

const StyledBackground = styled.div`
  && {
    position: relative;
    ${props =>
      props.background &&
      `background: url('${props.background}') no-repeat center;`}
    background-color: ${props => `${props.color}` || "#fff"};
    background-size:cover;
  }
`

const StyledContent = styled.div`
  && {
    padding: 3rem 0;
    ${props => props.center && "text-align:center"}
  }
`

/**
 * 
 MasonryBlock{
    list
    { title content button link color image{publicURL} }
    background
    color
    shadow
    center
    position
    number
    image{publicURL}
  }
 */

export const MasonryBlock = {
  label: "Masonry",
  name: "MasonryBlock",
  key: "MasonryBlock",
  defaultItem: {
    MasonryBlock: {
      list: [
        { title: "", content: "", button: "", link: "", color: "", image: {} },
      ],
      background: "#fff",
      color: "",
      shadow: "",
      center: "",
      position: "",
      number: "4",
      image: {},
    },
  },
  fields: [
    {
      label: "Masonry List",
      name: "MasonryBlock.list",
      component: "group-list",
      description: "Icon List",
      itemProps: item => ({
        key: item.id,
        label: item.name,
      }),
      defaultItem: () => ({
        name: "New Block",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Button",
          name: "button",
          component: "text",
        },
        {
          label: "Link",
          name: "link",
          component: "text",
        },
        {
          name: "color",
          label: "Background Color",
          component: "color",
          colorFormat: "hex",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
      ],
    },
    {
      name: "MasonryBlock.number",
      label: "Elements per row",
      component: "text",
    },
    {
      name: "MasonryBlock.position",
      label: "Image on Top",
      component: "toggle",
    },
    {
      name: "MasonryBlock.center",
      label: "Center",
      component: "toggle",
    },
    {
      name: "MasonryBlock.shadow",
      label: "Shadow",
      component: "toggle",
    },
    {
      name: "MasonryBlock.color",
      label: "Background Color",
      component: "color",
      colorFormat: "hex",
    },
    {
      label: "Background Image",
      name: "MasonryBlock.image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode) return ""
        return imageNode.publicURL
      },
    },
    //...
  ],
}
