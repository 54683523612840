export function Spacer({ data }) {
  return null
}

export const SpacerBlock = {
  label: "Spacer",
  name: "SpacerBlock",
  key: "SpacerBlock",
  defaultItem: {},
  fields: [],
}
